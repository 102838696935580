import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosError } from 'axios';
import { getSession, signOut } from 'next-auth/react';
import { env } from '@/env.mjs';

// Add this type extension
declare module 'next-auth' {
  interface Session {
    accessToken?: string;
  }
}

const API_BASE_URL = env.NEXT_PUBLIC_API_BASE_URL;

const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const session = await getSession();
  if (session?.accessToken) {
    config.headers.set('Authorization', `Bearer ${session.accessToken}`);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && originalRequest && !originalRequest.headers['X-Retry']) {
      originalRequest.headers['X-Retry'] = 'true';
      try {
        const session = await getSession();
        if (session?.error === "RefreshAccessTokenError" || !session?.accessToken) {
          await signOut({ redirect: false });
          window.location.href = '/auth/sign-in';
          return Promise.reject(error);
        }
        if (session?.accessToken) {
          originalRequest.headers['Authorization'] = `Bearer ${session.accessToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        await signOut({ redirect: false });
        window.location.href = '/auth/sign-in';
        return Promise.reject(refreshError);
      }
    }
    if (error.response?.status === 401) {
      await signOut({ redirect: false });
      window.location.href = '/auth/sign-in';
    }
    return Promise.reject(error);
  }
);

export default api;